import * as React from "react"

const Ele = ({ name }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      {name && name === "support-chat" && (
        <>
          <path d="M1 16v-6a9 9 0 1118 0v6"></path>
          <path d="M19 17a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3v5zM1 17a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H1v5z"></path>
        </>
      )}

      {name && name === "money" && (
        <>
          <rect width="20" height="12" x="2" y="6" rx="2" />
          <circle cx="12" cy="12" r="2" />
          <path d="M6 12h.01M18 12h.01" />
        </>
      )}

      {name && name === "gift" && (
        <>
          <path d="M20 12L20 22 4 22 4 12"></path>
          <path d="M2 7H22V12H2z"></path>
          <path d="M12 22L12 7"></path>
          <path d="M12 7H7.5a2.5 2.5 0 010-5C11 2 12 7 12 7zM12 7h4.5a2.5 2.5 0 000-5C13 2 12 7 12 7z"></path>
        </>
      )}

      {name && name === "dollar" && (
        <>
          <path d="M12 2L12 22"></path>
          <path d="M17 5H9.5a3.5 3.5 0 000 7h5a3.5 3.5 0 010 7H6"></path>
        </>
      )}

      {name && name === "fire" && (
        <>
          <path d="M8.5 14.5A2.5 2.5 0 0011 12c0-1.38-.5-2-1-3-1.072-2.143-.224-4.054 2-6 .5 2.5 2 4.9 4 6.5 2 1.6 3 3.5 3 5.5a7 7 0 11-14 0c0-1.153.433-2.294 1-3a2.5 2.5 0 002.5 2.5z"></path>
        </>
      )}

      {name && name === "home" && (
        <>
          <path d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2z"></path>
          <path d="M9 22L9 12 15 12 15 22"></path>
        </>
      )}

      {name && name === "chart" && (
        <>
          <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
          <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
        </>
      )}

      {name && name === "calendar" && (
        <>
          <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
          <path d="M16 2L16 6"></path>
          <path d="M8 2L8 6"></path>
          <path d="M3 10L21 10"></path>
        </>
      )}

      {name && name === "list" && (
        <>
          <path d="M8 6L21 6"></path>
          <path d="M8 12L21 12"></path>
          <path d="M8 18L21 18"></path>
          <path d="M3 6L3.01 6"></path>
          <path d="M3 12L3.01 12"></path>
          <path d="M3 18L3.01 18"></path>
        </>
      )}

      {name && name === "graduation" && (
        <>
          <path d="M22 10v6M2 10l10-5 10 5-10 5z"></path>
          <path d="M6 12v5c3 3 9 3 12 0v-5"></path>
        </>
      )}

      {name && name === "briefcase" && (
        <>
          <path d="M19 5H3a2 2 0 00-2 2v10a2 2 0 002 2h16a2 2 0 002-2V7a2 2 0 00-2-2z"></path>
          <path d="M15 19V3a2 2 0 00-2-2H9a2 2 0 00-2 2v16"></path>
        </>
      )}

      {name && name === "hourglass" && (
        <path d="M5 22h14M5 2h14M17 22v-4.172a2 2 0 00-.586-1.414L12 12l-4.414 4.414A2 2 0 007 17.828V22M7 2v4.172a2 2 0 00.586 1.414L12 12l4.414-4.414A2 2 0 0017 6.172V2"></path>
      )}

      {name && name === "heart" && (
        <path d="M20.42 4.58a5.4 5.4 0 00-7.65 0l-.77.78-.77-.78a5.4 5.4 0 00-7.65 0C1.46 6.7 1.33 10.28 4 13l8 8 8-8c2.67-2.72 2.54-6.3.42-8.42z"></path>
      )}

      {name && name === "sun" && (
        <>
          <circle cx="12" cy="12" r="4"></circle>
          <path d="M12 2v2M12 20v2M4.93 4.93l1.41 1.41M17.66 17.66l1.41 1.41M2 12h2M20 12h2M6.34 17.66l-1.41 1.41M19.07 4.93l-1.41 1.41"></path>
        </>
      )}

      {name && name === "network" && (
        <path d="M5.5 8.5L9 12l-3.5 3.5L2 12l3.5-3.5zM12 2l3.5 3.5L12 9 8.5 5.5 12 2zM18.5 8.5L22 12l-3.5 3.5L15 12l3.5-3.5zM12 15l3.5 3.5L12 22l-3.5-3.5L12 15z"></path>
      )}

      {name && name === "group" && (
        <>
          <path d="M16 21v-2a4 4 0 00-4-4H6a4 4 0 00-4 4v2"></path>
          <circle cx="9" cy="7" r="4"></circle>
          <path d="M22 21v-2a4 4 0 00-3-3.87M16 3.13a4 4 0 010 7.75"></path>
        </>
      )}

      {name && name === "chat" && (
        <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
      )}

      {name && name === "phone" && (
        <path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"></path>
      )}

      {name && name === "help" && (
        <>
          <circle cx="12" cy="12" r="10"></circle>
          <path d="M9.09 9a3 3 0 015.83 1c0 2-3 3-3 3"></path>
          <path d="M12 17L12.01 17"></path>
        </>
      )}

      {name && name === "bulb" && (
        <>
          <path d="M9 18L15 18"></path>
          <path d="M10 22L14 22"></path>
          <path d="M15.09 14c.18-.98.65-1.74 1.41-2.5A4.65 4.65 0 0018 8 6 6 0 006 8c0 1 .23 2.23 1.5 3.5A4.61 4.61 0 018.91 14"></path>
        </>
      )}
      {name && name === "book" && (
        <>
          <path d="M4 19.5A2.5 2.5 0 016.5 17H20"></path>
          <path d="M6.5 2H20v20H6.5A2.5 2.5 0 014 19.5v-15A2.5 2.5 0 016.5 2z"></path>
        </>
      )}

      {name && name === "chevrons" && (
        <path d="M7 20l5-5 5 5M7 4l5 5 5-5"></path>
      )}

      {name && name === "umbrella" && (
        <>
          <path d="M22 12a9.92 9.92 0 00-3.24-6.41 10.12 10.12 0 00-13.52 0A9.92 9.92 0 002 12zM12 12v8a2 2 0 004 0"></path>
          <path d="M12 2L12 3"></path>
        </>
      )}
      {name && name === "news" && (
        <>
          <path d="M4 22h16a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v16a2 2 0 0 1-2 2Zm0 0a2 2 0 0 1-2-2v-9c0-1.1.9-2 2-2h2" />
          <path d="M18 14h-8" />
          <path d="M15 18h-5" />
          <path d="M10 6h8v4h-8V6Z" />
        </>
      )}

      {name && name === "building" && (
        <>
          <rect width="16" height="20" x="4" y="2" rx="2" ry="2"></rect>
          <path d="M9 22v-4h6v4M8 6h.01M16 6h.01M12 6h.01M12 10h.01M12 14h.01M16 10h.01M16 14h.01M8 10h.01M8 14h.01"></path>
        </>
      )}

      {name && name === "goal" && (
        <>
          <path d="M12 13V2l8 4-8 4" />
          <path d="M20.561 10.222a9 9 0 1 1-12.55-5.29" />
          <path d="M8.002 9.997a5 5 0 1 0 8.9 2.02" />
        </>
      )}
    </svg>
  )
}
export default Ele
