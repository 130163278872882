import { keyframes } from "styled-components"

export const fadeInUp = keyframes`
    0%{
        opacity: 0;
        transform: translate3d(0,-50%, 0);
    }
    100%{
        opacity: 1;
        transform: none;
    }
`

export const fadeInLeft = keyframes`
  0%{
    opacity: 0;
    transform: translate3d(2rem, 0, 0); 
  }

  100% {
    opacity: 1; 
    transform: none; 
  }`
