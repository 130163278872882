import * as React from "react"
import styled from "styled-components"
import Icon from "./icon"

const Box = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
  position: relative;
  padding-right: 1.5rem;
  transition: opacity 0.3s ease, transform 0.3s ease;

  &:hover {
    opacity: 50%;
    transform: translate3d(0.5rem, 0, 0);
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-shrink: 0;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }

    &:before {
      border: solid 1px #00e2c2;
      opacity: 10%;
    }

    &:after {
      border-radius: 6px;
      background-image: linear-gradient(
        45deg,
        rgb(0 226 194 / 0%) 40%,
        #00e2c2
      );
      opacity: 30%;
    }
  }

  .label {
    font-size: 0.75rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    letter-spacing: -0.2px;
    opacity: 90%;
    white-space: nowrap;
  }

  svg {
    position: relative;
    z-index: 1;
    width: 50%;
    height: 50%;

    path,
    rect,
    circle {
      stroke: #00e2c2;
    }
  }

  .byline {
    opacity: 40%;
    font-weight: normal;
    padding-top: 1px;
    letter-spacing: 0;
  }
`

const Ele = ({ label, byline, icon }) => {
  return (
    <Box>
      <div className="icon">
        <Icon name={icon} />
      </div>
      <div className="label">
        <span>{label}</span>
        <span className="byline">{byline}</span>
      </div>
    </Box>
  )
}
export default Ele
