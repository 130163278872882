import * as React from "react"
import styled, { css } from "styled-components"

const Box = styled.button`
  width: min(12vw, 5rem);
  height: min(12vw, 5rem);
  display: none;
  z-index: 3;

  @media (max-width: 766px) {
    display: block;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 35%;
    width: 45%;
    margin: 0 auto;
    position: relative;
  }

  span {
    height: 2px;
    width: 100%;
    background-color: var(--c-green-200);
    transition: transform 0.3s;

    @media (prefers-color-scheme: light) {
      background-color: var(--c-green-200);
    }
  }

  ${props =>
    props.open &&
    css`
      div {
        position: relative;
      }

      span {
        position: absolute;
        transform: rotateZ(45deg);
        top: 0.5rem;

        &:first-child {
          transform: rotateZ(-45deg);
        }
      }
    `}
`

const Ele = ({ click }) => {
  const [state, setState] = React.useState(false)
  const handleClick = () => {
    setState(!state)
    click(!state)
  }
  return (
    <Box open={state} onClick={() => handleClick()}>
      <div>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </Box>
  )
}
export default Ele
