import * as React from "react"
import styled, { css } from "styled-components"
import { fadeInUp } from "../../utilities/aimations"

const Box = styled.ul`
  height: 5rem;
  display: flex;
  padding-right: 2rem;

  @media (max-width: 766px) {
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    z-index: 2;
    background-color: var(--c-blue-101);
    width: 100%;
    padding: 0;
    display: none;
    padding-top: 2rem;

    ${props =>
      props.visible &&
      css`
        display: block;
        animation: ${fadeInUp} 0.7s cubic-bezier(0.33, 1, 0.68, 1) both;
      `}
  }

  @media (prefers-color-scheme: light) and (max-width: 766px) {
    background-color: var(--c-white);
  }
`

const Ele = ({ children, visible }) => {
  return <Box visible={visible}>{children}</Box>
}
export default Ele
